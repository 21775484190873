function validarRUT(rut) {
  const Fn = {
    // Valida el rut con su cadena completa "XXXXXXXX-X"
    validaRut: function (rutCompleto) {
      if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
      const tmp = rutCompleto.split("-");
      let digv = tmp[1];
      const rut = tmp[0];

      if (digv == "K") {
        digv = "k";
      }

      return Fn.dv(rut) == digv;
    },
    dv: function (T) {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    },
  };

  return Fn.validaRut(rut);
}

function validarRUTEmpresa(rut) {
  var Fn = {
    validaRutEmpresa: function (rutCompleto) {
      var tmp = rutCompleto.split("-");
      var rut = tmp[0];
      if (rut < 50000000) {
        return false;
      }
      return true;
    },
  };
  return Fn.validaRutEmpresa(rut);
}

export default {
  required: function (propertyType) {
    return (v) => (v && v.length > 0) || `Campo ${propertyType} es requerido`;
  },
  requiredObject: function (propertyType) {
    return (v) => (v ? true : `Campo ${propertyType} es requerido`);
  },
  requiredYear: function (propertyType) {
    return (v) => (v && v > 0) || `Campo ${propertyType} es requerido`;
  },
  validateEmail: function () {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return (v) => pattern.test(v) || "Correo debe ser válido";
  },
  validateRFC: function () {
    const validation = /^[A-Za-zñÑ&]{1,2}([A-Za-zñÑ&]([A-Za-zñÑ&](\d(\d(\d(\d(\d(\d(\w(\w(\w)?)?)?)?)?)?)?)?)?)?)?$/;
    return (v) => validation.test(v) || "RFC debe ser valido";
  },
  validateNIT: function () {
    const validation = /(^[0-9]+-{1}[0-9]{1})/;
    return (v) => validation.test(v) || "NIT debe ser valido";
  },
  validateRUC: function () {
    const validation = /^((?!(10))[0-9]{11})$/;
    return (v) => validation.test(v) || "RUC debe ser valido";
  },
  validateRUT: function () {
    return (v) => {
      let codes = ["cec_1a4992-0"];
      if (codes.find((e) => v == e)) {
        return true;
      } else {
        return validarRUT(v) || "RUT inválido (Formato: 12345678-9)";
      }
    };
  },
  validateRUTEmpresa: function () {
    return (v) => validarRUTEmpresa(v) || "Debes ingresar un RUT de Empresa válido";
  },
  rut: function (rutCompleto) {
    const dv = (T) => {
      var M = 0,
        S = 1;
      for (; T; T = Math.floor(T / 10)) S = (S + (T % 10) * (9 - (M++ % 6))) % 11;
      return S ? S - 1 : "k";
    };
    if (!/^[0-9]+[-|‐]{1}[0-9kK]{1}$/.test(rutCompleto)) return false;
    var tmp = rutCompleto.split("-");
    var digv = tmp[1];
    var rut = tmp[0];
    if (digv == "K") digv = "k";
    return dv(rut) == digv;
  },
  validatePassword: function () {
    const pattern = new RegExp(
      "^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*](?=\\S+$).{8,32}$"
    );
    return (v) =>
      (v && v.length >= 8 && pattern.test(v)) || "La contraseña no cumple con los requisitos mínimos de seguridad.";
  },
  validateMaxLength: function (max) {
    return (v) => (v && v.length <= max) || `Máximo ${max} carácteres`;
  },
  validateMaxNumber: function (max) {
    return (v) => (v && v <= max) || `Máximo ${max}`;
  },
  validateMaxItems: function (max) {
    return (v) => (v && v.length <= max) || `Máximo ${max} opciones`;
  },
  validWorkers: function () {
    const pattern = new RegExp("^([0-9_])+$");
    return (v) => {
      if (v) {
        return (v > 0 && pattern.test(v)) || "Debes ingresar la cantidad de trabajadores valida";
      }
      return true;
    };
  },
  validYear() {
    const pattern = new RegExp("^\\d{4}$");
    const currentYear = new Date().getFullYear();

    return (v) => {
      if (v) {
        return (pattern.test(v) && parseInt(v) <= currentYear && v > 1000) || "Debes ingresar un formato de año válido";
      }

      return true;
    };
  },
};
