<template>
  <v-row
    :justify="justifyRow"
    :align="alignRow"
    :align-self="alignSelfRow"
    :justify-self="justifySelfRow"
    class="ma-0"
    :class="classRow"
  >
    <slot />
    <v-col
      v-if="!!$slots.alone"
      class="ma-0 full-height"
      :class="classAlone"
      cols="12"
      xs="12"
      sm="12"
      md="12"
      lg="12"
      xl="12"
      :justify="justifyAlone"
      :align="alignAlone"
      :align-self="alignSelfAlone"
      :justify-self="justifySelfAlone"
    >
      <slot name="alone" />
    </v-col>
    <v-col
      v-if="!!$slots.left"
      class="ma-0 full-height"
      :class="classLeft"
      cols="12"
      xs="12"
      sm="12"
      md="6"
      lg="6"
      xl="6"
      :justify="justifyLeft"
      :align="alignLeft"
      :align-self="alignSelfLeft"
      :justify-self="justifySelfLeft"
    >
      <slot name="left" />
    </v-col>
    <v-col
      v-if="!!$slots.right"
      class="ma-0"
      :class="classRight"
      cols="12"
      xs="12"
      sm="12"
      md="6"
      lg="6"
      xl="6"
      :justify="justifyRight"
      :align="alignRight"
      :align-self="alignSelfRight"
      :justify-self="justifySelfRight"
    >
      <slot name="right" />
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({}),
  props: {
    classLeft: {
      type: String,
      default: "",
      required: false,
    },
    classRight: {
      type: String,
      default: "",
      required: false,
    },
    classRow: {
      type: String,
      default: "",
      required: false,
    },
    classAlone: {
      type: String,
      default: "",
      required: false,
    },
    justifyLeft: {
      type: String,
      default: undefined,
      required: false,
    },
    alignLeft: {
      type: String,
      default: undefined,
      required: false,
    },
    alignSelfLeft: {
      type: String,
      default: undefined,
      required: false,
    },
    justifySelfLeft: {
      type: String,
      default: undefined,
      required: false,
    },
    justifyRight: {
      type: String,
      default: undefined,
      required: false,
    },
    alignRight: {
      type: String,
      default: undefined,
      required: false,
    },
    alignSelfRight: {
      type: String,
      default: undefined,
      required: false,
    },
    justifySelfRight: {
      type: String,
      default: undefined,
      required: false,
    },
    justifyRow: {
      type: String,
      default: undefined,
      required: false,
    },
    alignRow: {
      type: String,
      default: undefined,
      required: false,
    },
    alignSelfRow: {
      type: String,
      default: undefined,
      required: false,
    },
    justifySelfRow: {
      type: String,
      default: undefined,
      required: false,
    },
    justifyAlone: {
      type: String,
      default: undefined,
      required: false,
    },
    alignAlone: {
      type: String,
      default: undefined,
      required: false,
    },
    alignSelfAlone: {
      type: String,
      default: undefined,
      required: false,
    },
    justifySelfAlone: {
      type: String,
      default: undefined,
      required: false,
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    left() {
      return !!this.$slots.left;
    },
  },
};
</script>
